import React, { useState } from "react"
import { graphql } from "gatsby"
import Contact from '../../components/pages/Contact'

const lang = 'ca'

const ContactUs = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "CONTACT-US"
  })

  return (
    <Contact
      lang={lang}
      seoData={seoData}
      urls={resultUrls}
      pageContext={pageContext}
    />
  )
}

export const pageQuery = graphql`
  query contactUsCaQuery {
    seoDataJson(name: { eq: "contact-us" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
  }
`

export default ContactUs
